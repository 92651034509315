<button class="support-button unique-button">
    <a href="/support" class="support-play"> Support </a>
</button>

<style>
    .support-button {
        margin-top: 10px;
        width: 120px;
    }

    .support-play {
        color: yellow;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 150px;
    }
</style>
