<script lang="ts">
    import AppliedThumbnail from "./AppliedThumbnail.svelte";
    import { fetchGet, fetchPost } from "../services/fetch";
    import { AppliedQuestionClientVm } from "./AppliedQuestionVm";
    import { toast } from "@zerodevx/svelte-toast";
    import { navigate } from "svelte-routing";

    import { onMount } from "svelte";
    //import AppliedImage from "./AppliedImage.svelte";

    export let charid: string; //null:create, string:edit

    console.log("appliedsolve loaded");
    let steps = 0;
    //let questionVm: AppliedQuestionVm | null = null;

    type Point = {
        x: number;
        y: number;
    };

    enum State {
        None = 1,
        CaptureHint1Y,
        CaptureHint2Y,
        CaptureHint3Y,
        Circle1,
        Circle2,
        Circle3,
        Circle4,
    }

    let currentState = State.None;

    let imageUrl: string | null = null;
    let fileName: string | null = null;
    let imageFile: File | null = null;

    let image: HTMLElement;
    let circle0: HTMLElement;
    let circle1: HTMLElement;
    let circle2: HTMLElement;
    let circle3: HTMLElement;

    let hint_line0: HTMLElement;
    let hint_line1: HTMLElement;
    let hint_line2: HTMLElement;

    let question = new AppliedQuestionClientVm();

    let circleRadius = 10;

    onMount(async () => {
        console.log("mounted");

        if (charid) {
            console.log(`charid: ${charid} `);
            await load();

            answerClick(null);

            handleAnswerClick(question.option1PositionX, question.option1PositionY);
            handleAnswerClick(question.option2PositionX, question.option2PositionY);
            handleAnswerClick(question.option3PositionX, question.option3PositionY);
            handleAnswerClick(question.option4PositionX, question.option4PositionY);

            //hintClick(null); zeros step1Y etc
            //question.encodedImageHint1 the image
            question.stepCount = 0;
            currentState = State.CaptureHint1Y;

            if (question.step1Y) handleHintClick(10, question.step1Y);

            if (question.step2Y) handleHintClick(10, question.step2Y);

            if (question.step3Y) handleHintClick(10, question.step3Y);
        } else {
            console.log(`no charid`);
        }
    });

    async function load() {
        var clientUrl = `/AppliedMath/GetProblem/${charid}`;

        question = await fetchGet<AppliedQuestionClientVm>(clientUrl);

        console.log(`questionVm: ${question}   `);
        steps = question.stepCount;

        imageUrl = `data:image/png;base64,${question.encodedFullImage}`;
    }

    async function save() {
        console.log("title=" + question.title);
        console.log("rating=" + question.suggested_rating);
        console.log("time=" + question.suggested_time);

        console.log("hint0=" + question.step1Y);
        console.log("hint1=" + question.step2Y);
        console.log("hint2=" + question.step3Y);

        console.log("question=" + question);
        console.log(question);

        let wrongStep1Step2 = question.step1Y > question.step2Y && question.step2Y != 0;
        let wrongStep2Step3 = question.step2Y > question.step3Y && question.step3Y != 0;

        if (wrongStep1Step2 || wrongStep2Step3) {
            alert("Hints are not in the correct order");
            return;
        }

        var url = "AppliedMath/StoreAppliedProblem";
        var charIdObject = await fetchPost<any, AppliedQuestionClientVm>(url, question);

        toast.push(`Save ok, CharId: ${charIdObject.charId}`, {
            classes: ["success"],
            pausable: true,
        });

        navigate(`/applied/${charIdObject.charId}`);
    }
    function hintClick(event: any) {
        console.log(event);
        question.step1Y = 0;
        question.step2Y = 0;
        question.step3Y = 0;

        question.stepCount = 0;

        currentState = State.CaptureHint1Y;
    }
    function answerClick(event: any) {
        console.log(event);
        currentState = State.Circle1;
    }

    function onImageClick(event: any) {
        //console.log(event);
        //console.log(event.clientX, event.clientY);
        const myimage = image as HTMLElement;
        const rect = myimage.getBoundingClientRect();

        let x = event.clientX;
        let y = event.clientY - rect.top;
        x = Math.floor(x);
        y = Math.floor(y);

        console.log(`Clicked at (${x}, ${y})`);

        if (currentState == State.CaptureHint1Y || currentState == State.CaptureHint2Y || currentState == State.CaptureHint3Y) {
            handleHintClick(x, y);
        } else if (
            currentState == State.Circle1 ||
            currentState == State.Circle2 ||
            currentState == State.Circle3 ||
            currentState == State.Circle4
        ) {
            handleAnswerClick(x, y);
        }
    }

    function handleHintClick(x: number, y: number) {
        //const myimage = image as HTMLElement;
        let hint = hint_line0 as HTMLElement;

        if (currentState === State.CaptureHint1Y) {
            question.step1Y = y;
            console.log(question.step1Y);
            hint = hint_line0 as HTMLElement;
            currentState = State.CaptureHint2Y;
            question.stepCount = 1;
        } else if (currentState === State.CaptureHint2Y) {
            question.step2Y = y;
            console.log(question.step2Y);
            hint = hint_line1 as HTMLElement;
            currentState = State.CaptureHint3Y;
            question.stepCount = 2;
        } else if (currentState === State.CaptureHint3Y) {
            question.step3Y = y;
            console.log(question.step3Y);
            hint = hint_line2 as HTMLElement;
            currentState = State.CaptureHint1Y;
            question.stepCount = 3;
        }

        hint.style.top = `${y}px`;
    }

    function handleAnswerClick(x: number, y: number) {
        const myimage = image as HTMLElement;
        let circle = circle0 as HTMLElement;

        const rect = myimage.getBoundingClientRect();
        console.log(`Clicked at (${x}, ${y})`);

        if (currentState == State.Circle1) {
            //circle = circle0 as HTMLElement; //already done
            question.option1PositionX = x;
            question.option1PositionY = y;
        } else if (currentState == State.Circle2) {
            circle = circle1 as HTMLElement;
            question.option2PositionX = x;
            question.option2PositionY = y;
        } else if (currentState == State.Circle3) {
            circle = circle2 as HTMLElement;
            question.option3PositionX = x;
            question.option3PositionY = y;
        } else if (currentState == State.Circle4) {
            circle = circle3 as HTMLElement;
            question.option4PositionX = x;
            question.option4PositionY = y;
        }
        circle.style.border = currentState == State.Circle1 ? "3px solid green" : "3px solid red";
        circle.style.position = "absolute";
        circle.style.left = `${x - circleRadius}px`;
        circle.style.top = `${y - circleRadius}px`;
        circle.style.width = `${circleRadius * 2}px`;
        circle.style.height = `${circleRadius * 2}px`;
        circle.style.borderRadius = "50%";

        if (currentState == State.Circle1) {
            currentState = State.Circle2;
        } else if (currentState == State.Circle2) {
            currentState = State.Circle3;
        } else if (currentState == State.Circle3) {
            currentState = State.Circle4;
        } else if (currentState == State.Circle4) {
            currentState = State.Circle1;
        }
    }

    function handleDrop(event: DragEvent) {
        event.preventDefault();
        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
            imageFile = files[0];
            fileName = imageFile.name;

            if (!imageFile.type.startsWith("image/")) {
                alert("Please drop a valid image file.");
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const dataUrl = e.target?.result as string;

                // For displaying the image
                imageUrl = dataUrl;

                // Extract base64 part of the data URL
                const base64String = dataUrl.split(",")[1];
                question.encodedFullImage = base64String;
            };
            reader.readAsDataURL(imageFile);

            question.title = fileName.split(".")[0];
        }
    }

    function handleDragOver(event: DragEvent) {
        event.preventDefault();
    }
    function handleKeyDown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            // alert('Please drop your image file here.');
        }
    }
</script>

<div class="drop-area" role="button" tabindex="0" on:drop={handleDrop} on:dragover={handleDragOver} on:keydown={handleKeyDown}>
    Drag & Drop an image here
</div>

{#if fileName}
    <p>File Name: {fileName}</p>
{/if}

<div>
    {#if question.step1Y}
        hint1: {question.step1Y}
    {/if}
    {#if question.step2Y}
        hint2: {question.step2Y}
    {/if}
    {#if question.step3Y}
        hint3: {question.step3Y}
    {/if}

    {#if question.option1PositionX}
        correct: {question.option1PositionX}, {question.option1PositionY}
    {/if}
    {#if question.option2PositionX}
        wrong1: {question.option2PositionX}, {question.option2PositionY}
    {/if}
    {#if question.option3PositionX}
        wrong2: {question.option3PositionX}, {question.option3PositionY}
    {/if}
    {#if question.option4PositionX}
        wrong3: {question.option4PositionX}, {question.option4PositionY}
    {/if}
</div>

{#if imageUrl}
    <div class="image-preview">
        <div bind:this={circle0} class="circle"></div>
        <div bind:this={circle1} class="circle"></div>
        <div bind:this={circle2} class="circle"></div>
        <div bind:this={circle3} class="circle"></div>

        <div bind:this={hint_line0} class="hint_line hint_line1"></div>
        <div bind:this={hint_line1} class="hint_line hint_line2"></div>
        <div bind:this={hint_line2} class="hint_line hint_line3"></div>

        <div role="button" tabindex="0" on:click={onImageClick} on:keydown={() => {}} style="display: inline-block;">
            <img alt="Dropped Image" src={imageUrl} bind:this={image} aria-hidden="true" />
        </div>
    </div>
    <!-- <button on:click={sendImageToServer}>Upload Image</button> -->
{/if}

<div class="create-button">
    <div class="nominal">
        <h3>Name: <input type="text" id="input" bind:value={question.title} /></h3>
        <h3 class="nominal-rating nmnl">
            Rating: <input type="number" id="input" name="quantity" min="1" max="3000" bind:value={question.suggested_rating} />
        </h3>
        <h3 class="nominal-time nmnl">
            Time: <input type="text" id="input" name="quantity" min="1" max="3000" bind:value={question.suggested_time} />
        </h3>
    </div>

    <button on:click={hintClick} class="hints btton">Hints</button>
    <button on:click={answerClick} class="set-answer btton">Set Answer Position</button>
    <button class="save btton" on:click={save}>Save</button>
</div>
<AppliedThumbnail></AppliedThumbnail>

<style>
    .drop-area {
        border: 2px dashed #ccc;
        border-radius: 4px;
        padding: 20px;
        text-align: center;
    }

    .image-preview {
        position: relative;
    }

    .image-preview img {
        display: block; /* Make sure the image is displayed as a block to avoid extra space */
        margin-top: 20px;
    }
    .circle {
        position: absolute;
        border-radius: 50%;
    }

    .hint_line {
        position: absolute;
        height: 2px;
        width: 100%;
    }

    .hint_line1 {
        background-color: cyan;
    }
    .hint_line2 {
        background-color: magenta;
    }
    .hint_line3 {
        background-color: yellow;
    }
</style>
