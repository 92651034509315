// Profile.ts
import { fetchGet } from './fetch';
import { rating } from './stores';
import { get } from 'svelte/store';
import { navigate, Link } from "svelte-routing";
import { UserService } from "./user.service";
import { toast } from "@zerodevx/svelte-toast";
import { userName, isAuthenticated } from "./stores";

//When we relaod, this is used to set isAuthenicated/username
//let loggedIn = UserService.isLoggedIn();


interface TaskInterfaceVm {
    Url: string;
    Title: string;
    Value: number;
}

export interface TaskVm {
    MemoryTasks: TaskInterfaceVm[];
    IntroTasks: TaskInterfaceVm[];
    MentalTasks: TaskInterfaceVm[];
    AppliedTasks: TaskInterfaceVm[];
    rating: number; //maybe this should have been in the progressVm
}


export async function getHomeTasksVm(): Promise<TaskVm> {
    // Get the initial value of the store
    let authenticated = get(isAuthenticated);
    if (authenticated) {
        const tasks = await fetchGet<TaskVm>("FastMentalMath/GetHomeTasks");
        console.log(tasks);
        return tasks;
    }
    else {
        throw new Error("User not authenticated");
    }
}

interface ProgressVm {
    introProgress: number;
    memoryProgress: number;
    mentalMathProgress: number;
    appliedMathProgress: number;
    // problemsSolved: number;
}

export async function getProgressVm() {
    let progress = await fetchGet<ProgressVm>("Progress/GetProgress");

    return progress;
}

export async function updateRating() {
    let tasks = await getHomeTasksVm();
    rating.set(tasks.rating);
}

