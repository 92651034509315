<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { isLoading } from "../services/stores";

  export let canvasid = "canvasid";

  let canvas: HTMLCanvasElement | null;
  let context: CanvasRenderingContext2D | null | undefined;
  let timerId: number;

  let fibtable = [0, 1];
  function fibs(n: number): number {
    if (n == 0) return 0;
    else if (n == 1) return 1;
    else {
      let r = fibtable[n - 1] + fibtable[n - 2];
      fibtable[n] = r;
      return r;
    }
  }

  let f = 1;
  let iteration = 1;
  let scale = 1; // Initial scale

  let top = 0;
  let left = 0;

  let centerX = 0;
  let centerY = 0;

  let oldF = 1;
  let worldScale = 110;
  let oldScreenF = oldF * worldScale;
  let screenF = f * worldScale;

  let minTop = 0;
  let minLeft = 100000;
  let maxRight = -1000000;

  let canvasSize = 0;
  let scaleFactor = 0.62;

  function restart() {
    f = 1;
    fibtable = [0, 1];
    iteration = 1;
    scale = 1;
    top = 0;
    left = 0;
    centerX = 0;
    centerY = 0;
    oldF = 1;
    worldScale = 110;
    oldScreenF = oldF * worldScale;
    screenF = f * worldScale;
    minTop = 0;
    minLeft = 100000;
    maxRight = -1000000;
    canvasSize = 0;
    scaleFactor = 0.62;

    canvas = document.getElementById(canvasid) as HTMLCanvasElement | null;
    context = canvas?.getContext("2d");
    adjustCanvasSize();

    canvasSize = canvas!.width;
    centerX = canvasSize / 2;
    centerY = canvasSize / 2;

    if (timerId) clearInterval(timerId);
    timerId = setInterval(animate, 1000) as unknown as number;
  }

  $: if ($isLoading) {
    //console.log(`start timer isLoading: ${$isLoading}`);
    if (timerId) clearInterval(timerId);
    timerId = setInterval(animate, 1000) as unknown as number;
  } else {
    //not loading anymore, stop timer

    //console.log(`start timer isLoading: ${$isLoading}`);
    if (timerId) clearInterval(timerId);
    //timerId = setInterval(animate, 1000) as unknown as number;
  }

  onMount(() => {
    //console.log("Fibonacci mounting");
    restart();
  });

  function adjustCanvasSize(): void {
    if (canvas) {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    }
  }

  function animate(): void {
    fibonacciSpiral();
    //testDot();
    scaledown();
  }

  // function testDot(): void {
  //   if (!canvas) throw "null canvas";
  //   if (!context) throw "null context";

  //   let colors = ["green", "gray", "brown", "beige", "cyan", "magenta"];
  //   const randomIndex = Math.floor(Math.random() * colors.length);
  //   let color = colors[randomIndex];

  //   const dotSize = 5;
  //   context.fillStyle = color;
  //   drawDot(context, centerX, centerY, dotSize / 2); // Call the function to draw the dot
  // }

  // function drawDot(ctx: CanvasRenderingContext2D, x: number, y: number, radius: number): void {
  //   if (!context) throw "null context";

  //   context.beginPath();
  //   context.arc(x, y, radius, 0, Math.PI * 2); // Draw a full circle
  //   context.fill(); // Fill the circle to create a dot
  // }

  //draw a rectangle that verifies if scaling is done correctly
  //it should be on top of previous test rect
  // function testRect(): void {
  //   if (!canvas) throw "null canvas";
  //   if (!context) throw "null context";

  //   let colors = ["cyan", "magenta", "green", "red"];
  //   let color = colors[iteration%4];

  //   context.strokeStyle = color;
  //   context.lineWidth = 2;

  //   context.rect(canvasSize * 0.25, centerY - canvasSize * 0.25, canvasSize * 0.5, canvasSize * 0.5);
  //   context.stroke();
  // }

  function scaledown(): void {
    if (!canvas) throw "null canvas";
    if (!context) throw "null context";

    context.save();

    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    if (!tempCtx) throw "null tempCtx";

    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;

    tempCtx.drawImage(canvas, 0, 0);

    //context.clearRect(0, 0, canvas.width, canvas.height);
    context.clearRect(0, 0, canvasSize, canvasSize);

    //always scale down the image by the same amount, not cummulative
    context.setTransform(1, 0, 0, 1, 0, 0); // Reset transformations
    context.scale(scaleFactor, scaleFactor); //this makes dx and dy calculation difficult

    let dx = ((canvas.width * (1 - scaleFactor)) / 2) * (1 / scaleFactor);
    let dy = ((canvas.height * (1 - scaleFactor)) / 2) * (1 / scaleFactor);

    context.drawImage(tempCanvas, dx, dy); //this image should be scaled 50%
    context.restore();
    context.scale(scaleFactor, scaleFactor);
    canvasSize = canvasSize / scaleFactor;
    centerX = canvasSize / 2;
    centerY = canvasSize / 2;
  }

  function fibonacciSpiral(): void {
    if (!canvas) throw "null canvas";
    if (!context) throw "null context";

    context.strokeStyle = "pink";
    context.lineWidth = 1;
    context.font = "50px Roboto Mono";
    context.fillStyle = "yellow";
    // context.fillText("Loading", 10, 50);

    // Draw the rectangle
    context.beginPath();

    // right
    //  ---
    // |   |
    //  ---

    // up
    //  ---
    // |   |
    //  ---
    //  ---
    // |   |
    //  ---

    // left
    //  ---------   ---
    // |         | |   |
    // |         |  ---
    // |         |  ---
    // |         | |   |
    //  ---------   ---

    // down
    //  ---------   ---
    // |         | |   |
    // |         |  ---
    // |         |  ---
    // |         | |   |
    //  ------   ---
    //  ----------------
    // |               |
    // |               |
    // |               |
    // |               |
    // |               |
    //  ----------------

    // right
    //  ------   ---  ---------------------
    // |      | |   |                       |
    // |      |  ---                        |
    // |      |  ---                        |
    // |      | |   |                       |
    //  ------   ---                        |
    //  ------------                        |
    // |            |                       |
    // |            |                       |
    // |            |                       |
    // |            |                       |
    //  ------------ -----------------------

    oldF = f;
    f = fibs(iteration);

    console.log(`F ${f}`);

    oldScreenF = oldF * worldScale;
    screenF = f * worldScale;

    let goRight = iteration % 4 == 3;
    let goUp = iteration % 4 == 0;
    let goLeft = iteration % 4 == 1;
    let goDown = iteration % 4 == 2;

    if (goRight) {
      left = left + oldScreenF;
      top = minTop; //Maybe not a good idea first time, set minTop to the correct before beginning
    } else if (goUp) {
      top = top - screenF;
      left = minLeft;
    } else if (goLeft) {
      left = left - screenF;
    } else if (goDown) {
      top = top + oldScreenF;
      left = minLeft;
    }

    if (left + screenF > maxRight) maxRight = left + screenF;

    if (left < minLeft) minLeft = left;
    if (top < minTop) minTop = top;

    let colors = ["cornflowerblue", "mediumaquamarine", "goldenrod", "chocolate"];
    let color = colors[iteration % 4];

    context.fillStyle = color;
    context.strokeStyle = color;

    context.fillRect(centerX + left, centerY + top, screenF, screenF); // Fill the rectangle with color

    context.strokeStyle = "black";
    context.lineWidth = (1 * canvasSize) / 500.0;
    context.rect(centerX + left, centerY + top, screenF, screenF);
    context.stroke();

    iteration++;

    if (iteration > 176) restart();
  }

  onDestroy(() => {
    if (timerId) clearInterval(timerId);
    console.log("Fibonacci un-mounting");
  });
</script>

<div class="fibonac">
  <canvas id={canvasid} bind:this={canvas}></canvas>
  <div>iteration = {iteration}</div>
  <div>fibonacci(n)={f}</div>
  <div>fibonacci(n-1)={oldF}</div>
  <div>fibonacci(n)/fib(n-1) = {f / oldF}</div>
</div>

<style>
  canvas {
    width: 100%;
    height: 100%;
  }
  .fibonac {
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    position: relative;
  }
</style>
