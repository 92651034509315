<script lang="ts">
	import { onMount, onDestroy } from "svelte";
	import { connectionTrouble } from "./services/stores"; // Adjust path as necessary

	let trouble = false;

	const unsubscribe = connectionTrouble.subscribe((value) => {
		trouble = value;

		//console.log(`changed trouble to ${trouble}`);
	});

	// Don't forget to unsubscribe when the component is destroyed
	onDestroy(unsubscribe);
</script>

{#if trouble}
	<h3 class="error">Error connecting to the server. Please check your connection.</h3>
{/if}
{#if !trouble}
	<!-- <h1 style="color: green;">No problems with network connectivity.</h1> -->
{/if}

 <style>
	.error{
		color: yellow;
		animation: pulse 1s infinite alternate;
	}
	

  @keyframes pulse {
    0% {
      color: yellow;
    }
    100% {
      color: red;
    }
  }
 </style>