<script lang="ts">
    // import "./switch.css"; caused problems on other pages
    // import "./switch.css";
    // import { writable } from "svelte/store";

    // const isChecked = writable(false);
    // const toggleSwitch = (event: Event) => {
    // 	const target = event.target as HTMLInputElement;
    // 	isChecked.set(target.checked);
    // };
	let isTesting = false;
</script>

<div class="switch-button">
	<p class="testing {!isTesting ? 'active learn-active' : ''}">Learn</p>
	<div class="toggle">
		<input type="checkbox" id="btn" bind:checked={isTesting} />
		<label for="btn">
			<span class="track">
				<span class="txt"></span>
			</span>
			<span class="thumb">|||</span>
		</label>
	</div>

	<p class="testing {isTesting ? 'active test-active' : ''}">Test</p>
</div>

<style>
	:root {
		--sz: 20px;
	}

	.switch-button {
		position: relative;
		width: 250px;
		display: flex;
		flex-direction: row;
		margin-top: 40px;
		gap: 10px;
		align-items: center;
		justify-content: center;
	}

	* {
		box-sizing: border-box;
		transition: var(--tr);
	}

	.toggle {
		left: 10%;
		height: calc(var(--sz) * 2);
		--on: #2eddf3;
		--of: #00ffc8;
		--transition-duration: 0.3s;
		overflow: hidden;
		font-family: Roboto, Arial, Helvetica, serif;
	}

	input {
		display: none;
	}

	label[for="btn"] {
		position: absolute;
		width: calc(var(--sz) * 4);
		height: calc(var(--sz) * 2);
		background: linear-gradient(180deg, #888, #fff);
		border-radius: var(--sz);
		padding: calc(var(--sz) / 5);
		box-shadow: 0 0 calc(var(--sz) / 50) calc(var(--sz) / 50) #0002;
	}

	#btn:checked + label[for="btn"] {
		box-shadow:
			0 calc(var(--sz) / 50) calc(var(--sz) / 5) #fff9,
			0 0 calc(var(--sz) / 50) calc(var(--sz) / 50) #0002;
	}

	#btn:checked + label[for="btn"]:after {
		opacity: 1;
		width: calc(100% - calc(calc(var(--sz) / 5) * 4));
	}

	.track {
		left: 4px;
		position: absolute;
		width: calc(calc(var(--sz) * 4) - calc(var(--sz) / 2.5));
		height: calc(calc(var(--sz) * 2) - calc(var(--sz) / 2.5));
		border-radius: var(--sz);
		overflow: hidden;
	}

	.track:before {
		content: "";
		position: absolute;
		width: calc(200% - calc(calc(var(--sz) * 2.3) - calc(var(--sz) / 3)));
		height: 100%;
		left: -43%;
		transition: transform 0.3s ease;
		background: linear-gradient(90deg, var(--on) 50%, var(--of) 50%);
		/* animation: move-color 3s ease 0s 1; */
	}

	.track:after {
		left: 0;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: var(--sz);
		box-shadow:
			0 calc(var(--sz) / -10) calc(var(--sz) / 10) 0 #0005 inset,
			0 calc(var(--sz) / 10) calc(var(--sz) / 10) 0 #0005 inset;
	}

	#btn:checked + label .track:before {
		left: 0%;
	}

	.thumb {
		position: absolute;
		width: calc(var(--sz) * 1.8);
		height: calc(var(--sz) * 1.8);
		top: calc(var(--sz) / 10);
		left: calc(var(--sz) / 10);
		background: linear-gradient(180deg, #fff, #afafaf);
		border-radius: var(--sz);
		box-shadow:
			0 0 0 calc(var(--sz) / 50) #0002,
			0 calc(var(--sz) / 10) calc(var(--sz) / 10) 0 #0008;
		cursor: pointer;
		font-size: calc(var(--sz) / 2);
		display: flex;
		align-items: center;
		justify-content: center;
		text-indent: calc(var(--sz) * -0.025);
		text-shadow:
			calc(var(--sz) / -8000) calc(var(--sz) / -150) calc(var(--sz) / 50) #0008,
			calc(var(--sz) / 150) calc(var(--sz) / 100) calc(var(--sz) / 50) #fff;
		color: #00ffc8;
		z-index: 1;
		transition: transform 0.3s ease;
		/* animation: move-thumb 3s ease 0s 1; */
	}

	#btn:checked + label .thumb {
		transform: translateX(calc(100% - var(--sz)));
		left: calc(calc(78% - calc(calc(var(--sz) * 2) - calc(var(--sz) / 3))) - calc(calc(var(--sz) / 10) + calc(var(--sz) / 15)));
		color: var(--on);
		/* background: radial-gradient(circle at 7% 50%, #fff calc(var(--sz) / 50), #fff0 calc(var(--sz) / 2)),
			radial-gradient(circle at 0 50%, var(--on) 10%, #fff0 60%), linear-gradient(180deg, #fff, #afafaf); */
		text-shadow:
			0 0 calc(var(--sz) / 10) var(--on),
			0 0 calc(var(--sz) / 5) #fff,
			calc(var(--sz) / -8000) calc(var(--sz) / -150) calc(var(--sz) / 50) #0008,
			calc(var(--sz) / 150) calc(var(--sz) / 100) calc(var(--sz) / 50) #fff;
	}

	.thumb:before {
		content: "";
		display: block;
		width: 70%;
		height: 70%;
		background: linear-gradient(180deg, #0008, #ccc, #fff);
		position: absolute;
		z-index: -1;
		border-radius: var(--sz);
		border: calc(var(--sz) / 50) solid #ddd;
	}

	.testing {
		color: white;
		margin-left: 30%;
		transition: color 0.3s ease;
	}

	.active {
		margin-left: 30%;
		transition: color 0.3s ease;
	}
	.learn-active {
		color: #00ffc8;
	}

	.test-active {
		color: #2eddf3;
	}

	/* .txt:before,
.txt:after {
    content: "";
    position: absolute;
    left: calc(var(--sz) / 2);
    top: 25%;
    content: "TESTING"; 
    transition: var(--tr);
    font-size: 10px;
    color: #000;
    opacity: 0.4;
    text-shadow: 0px -1px 1px #000, 0px 1px 2px #fff;
} */
	/* 
.txt:after {
    content: "LEARNING";
    left: calc(100% - calc(var(--sz) / 0.625));
} */
</style>

