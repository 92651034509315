<!-- Landing.svelte -->
<script lang="ts">
    import { navigate } from "svelte-routing";
    import Login from "../User/Login.svelte";
    //import Cookie from "./Cookie.svelte";
    import "../App/app.css";
    import { onMount } from "svelte";
    import Achievement from "../User/Achievements.svelte";
    import PlezuzPuzzle from "./PlezuzPuzzle.svelte";
    import KeypadVar from "../Keypadmem/KeysEq.svelte";
    import Premium from "../Payment/CurrentLimit.svelte";
    import CurrentLimit from "../Payment/CurrentLimit.svelte";
    import Trophy from "../Keypadmem/Trophy.svelte";

    onMount(() => {});

    // let isMenuOpen = false;

    // const toggleMenu = () => {
    //     // event.preventDefault();
    //     isMenuOpen = !isMenuOpen;
    // };

    // Close the menu when clicking outside of it
    // onMount(() => {
    //     const closeMenuOnClickOutside = (event) => {
    //         if (isMenuOpen && !event.target.closest(".menu-button")) {
    //             toggleMenu();
    //         }
    //     };

    //     document.addEventListener("click", closeMenuOnClickOutside);

    //     return () => {
    //         document.removeEventListener("click", closeMenuOnClickOutside);
    //     };
    // });
</script>

<!-- <div class="container-flex">
<Menu hamburger={true}></Menu>
<Profile></Profile>
</div> -->

<!-- <Cookie></Cookie> -->

<div class="login-signup-button">
    <PlezuzPuzzle></PlezuzPuzzle>
    <button on:click={() => navigate("/login")} data-test="login-button" type="button" 
        >LOG IN</button
    >

    <button on:click={() => navigate("/signup")} data-test="signup-button" type="button"
        >SIGN UP</button
    >
</div>
<!-- <Trophy color="gold"></Trophy> -->
<!-- <Trophy color="silver"></Trophy> -->
<!-- <Trophy color="bronze"></Trophy>  -->