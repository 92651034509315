<script lang="ts">
	import "./Home.css";
	import { Link } from "svelte-routing";

	export let taskNumber: number;
	export let progress: number;

	export let appliedTaskTable: TaskInterfaceVm[];

	interface TaskInterfaceVm {
		Url: string;
		Title: string;
		Value: number;
	}

	let title = "";
	let url = "";
	let achieved = 100;

	$: if (appliedTaskTable && taskNumber <= appliedTaskTable.length && taskNumber > 0) {
		//url = "appliedmath/" + appliedTaskTable[taskNumber - 1].Url;
		url = "appliedmath";
		title = appliedTaskTable[taskNumber - 1].Title;
		achieved = appliedTaskTable[taskNumber - 1].Value;
		console.log(`AppliedMathLink taskNumber: ${taskNumber} title: ${title} achieved: ${achieved}`);
	}
	//$: console.log(achieved);
</script>

{#if progress >= taskNumber}
	<Link to={url} data-toggle="collapse" data-target=".navbar1-collapse" class="menu-link">
		<div class="small-circle button applied-green" {title}>
			{#if achieved == 10}
				<div class="gld circle"></div>
			{:else if achieved == 20}
				<div class="slvr circle"></div>
			{:else if achieved == 30}
				<div class="brnz circle"></div>
			{/if}
		</div>
	</Link>
	<span class="title">{title}</span>
{:else}
	<div class="small-circle button gray" {title}></div>
{/if}


<style>
	.circle {
		position: relative;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		text-decoration: none;
		overflow: hidden;
		z-index: 2;
	}
	.circle::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
	}
	.slvr {
		border: 1px solid rgba(255, 255, 255, 0.247);
	}
	.slvr::before {
		background: linear-gradient(#ede8e8, #c2c2c2, #aeaeae, #9a9595, #e0dede);
	}
	.gld {
		border: 1px solid rgba(255, 221, 27, 0.324);
	}
	.gld::before {
		background: linear-gradient(#ffd782, #f3c334, #eabc32, #ffff95);
	}
	.brnz {
		border: 1px solid rgba(255, 255, 255, 0.274);
	}
	.brnz::before {
		background: linear-gradient(#ffd8d8, #e8b094, #d67650, #da8f69, #ffd2d2);
	}
	.title {
		font-size: 10px;
		font-weight: bold;
		margin-top: 30px;
		z-index: 1;
		position: absolute;
		bottom: 1px;
		pointer-events: none;
	}
</style>
