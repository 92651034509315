<script lang="ts">
	import { onMount, onDestroy } from "svelte";
	import { navigate, Link } from "svelte-routing";
	import { toast } from "@zerodevx/svelte-toast";
	import { UserService } from "../services/user.service";
	import { isAuthenticated, userName,onLoginPage } from "../services/stores";

	let submitted: boolean = false;
	let userService = new UserService();
	let errorMessageFromServer = "";
	let loggedIn: boolean = UserService.isLoggedIn();

	let user_name: string = "";
	let user_password = "";
	let isDisabled = true;

	let apiUrl = import.meta.env.VITE_API;
	let redirectUrl: string = "";
	$: isDisabled = !(user_name && user_password && allowLogin());



	export let slug: any;

	onMount(() => {
		//const params = new URLSearchParams(window.location.search);
		//redirectUrl = params.get("redirect") || "/";

		$onLoginPage = true;

		if (slug == "logout") {
			userService.logout();
			navigate("/login");
			location.reload(); //to remove the username from the topright that signals logged in status
		}
	});

	onDestroy(() => {
		$onLoginPage = false;
	});



	async function login() {
		try {
			const loggedInUser = await userService.login(user_name, user_password);

			if (loggedInUser && loggedInUser.token) {
				loggedIn = UserService.isLoggedIn();
				if (loggedIn) {
					userName.set(user_name);
					isAuthenticated.set(true);

					let redirectRoute = localStorage.getItem("redirectAfterLogin") || "/home";
					if (redirectRoute == "//") redirectRoute = "/"; //hack

					navigate(redirectRoute);
					localStorage.removeItem("redirectAfterLogin");

					toast.push("Logged in", {
						classes: ["success"],
						pausable: true,
					});

					//reload, to show the username (this should not be necessary)
					//location.reload();
				} else {
					toast.push("Could not log in.", {
						classes: ["error"],
						dismissable: true, // Adds a close button
						initial: 0,
					});
				}
			} else {
				const errorMessage = loggedInUser ? `Could not log in. ${loggedInUser}` : "Could not log in.";
				toast.push(errorMessage, { classes: ["error"], initial: 0 });
			}
		} catch (error: any) {
			// Handle login error
			toast.push(`Login failed: ${error.message}`, {
				classes: ["error"],
				initial: 0,
				dismissable: true, // Adds a close button
			});
		}
	}

	async function handleLoginSubmit(e: Event) {
		e.preventDefault(); //Stop obnoxious reload
		submitted = true;
		if (user_name && user_password) {
			await login();
		} else {
			if (!user_name) {
				toast.push("Invalid user name", {
					classes: ["error"],
					initial: 0,
					dismissable: true, // Adds a close button
				});
			} else if (!user_password) {
				toast.push("Invalid password", {
					classes: ["error"],
					initial: 0,
					dismissable: true, // Adds a close button
				});
			}
		}
	}

	function nameError(): boolean {
		return user_name.trim().length < 3;
	}

	function passwordError(): boolean {
		return user_password.length < 5;
	}

	function allowLogin(): boolean {
		return !nameError() && !passwordError();
	}
</script>

<!-- <button on:click={signup}> Button on the sign up page </button> -->

<div class="button-container">
	<!-- <button on:click={() => navigate("/landing")} data-test="close-button" class="close-button">
		<img
			src="https://d35aaqx5ub95lt.cloudfront.net/images/ed25a8cf69261b0c1e25b147f369f74a.svg"
			alt="Close button"
		/>
	</button> -->

	<form class="log-in" on:submit={handleLoginSubmit}>
		<div class="signup">
			<h2>Log In</h2>
			<div class="info">
				<div class="username">
					<input
						data-test="username-input"
						autocomplete="username"
						placeholder="Username"
						class="email-username"
						type="text"
						bind:value={user_name}
					/>
				</div>
				<div class="enter-pass">
					<div class="password">
						<input
							data-test="password-input"
							autocomplete="current-password"
							placeholder="Enter password"
							class="enterpass"
							type="password"
							bind:value={user_password}
						/>
						<!-- <button class="forgotpass" data-test="forgot-password" tabindex="-1">Forgot?</button> -->
					</div>
				</div>
			</div>

			<button on:click={handleLoginSubmit} class="login-button" data-test="register-button" type="submit" disabled={isDisabled}>
				LOG IN
			</button>
			<div style="color: white;">
				Don't have an account yet? <Link to="/signup">Signup</Link>
			</div>
		</div>
	</form>
</div>
<style>
.login-button {
  margin-top: 20px;
  width: 100%;
  border: 2px solid yellow;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.login-button:disabled {
  background-color: #d3d3d359;
  border: 2px solid #d3d3d3;
  color: #a7a7a7;
  cursor: not-allowed;
}
</style>