import SHA256 from 'crypto-js/sha256';

export class Hash {
	encrypt(password: string): string {
		const encryptedPw = SHA256(password);
		const str = encryptedPw.toString();
		return str;
	}

	// Rest of your code...

	//funker for aes, men ikke for hashing
	//decrypt(encryptedPassword: string): string {
	//	var bytes = CryptoJS.AES.decrypt(encryptedPassword, 'secret key');
	//	var plaintext = bytes.toString(CryptoJS.enc.Utf8);
	//	return plaintext;
	//}

	//test( code: string) {
	//	var encrypted = this.encrypt(code); var decrypted = this.decrypt(encrypted);
	//	if (decrypted != code)
	//			throw new Error("Failed hash test for " + code);
	//}

	//testAll() {
	//	this.test("AB");
	//	this.test("A");
	//	this.test("0");
	//	this.test("01234567889");
	//	this.test("01234567889abcdefghijklmnopqurstuvwxyz");
	//	this.test("01234567889abcdefghijklmnopqurstuvwxyzABCDEFGHIJKLMNOPQURSTUVWXYZ");
	//	this.test("01234567889abcdefghijklmnopqurstuvwxyzABCDEFGHIJKLMNOPQURSTUVWXYZ!#¤%&/()=?`^*;:_,.-<>");
	//}

}


