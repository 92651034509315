<script lang="ts">
import Podium from "./Podium.svelte";
   
    export let title;
    export let colorval;
    export let text;

    let isGold = false;
    let isSilver = false;
    let isBronze = false;
    $: if (colorval == "gold") {
        isGold = true;
        console.log("gold");
    }
    $: if (colorval == "silver") {
        isSilver = true;
        console.log("silver");
    }
    $: if (colorval == "bronze") {
        isBronze = true;
        console.log("bronze");
    }
</script>

<div class="achievements">
    <div class="achievement">
        {#if isGold}
            <div class="gold clr">
                <img alt="icon" src="/puzzle.svg" style="" width="60px" height="60px" />
            </div>
        {/if}
        {#if isSilver}
            <div class="silver clr">
                <img alt="icon" src="/puzzle.svg" style="" width="60px" height="60px" />
            </div>
        {/if}
        {#if isBronze}
            <div class="bronze clr">
                <img alt="icon" src="/puzzle.svg" style="" width="60px" height="60px" />
            </div>
        {/if}
        <div class="text">
            <div class="heading">{title}</div>
            <div class="child-text txt">{text}</div>
            <div>colorval:{colorval}</div>
        </div>
    </div>
</div>


<style>
    .achievements {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }
    .achievement {
        display: flex;
        position: relative;
        margin: 10px;
        color: white;
        width: 370px;
        height: 100px;
        background: white;
        border-radius: 5px;
        justify-content: start;
    }
    .text {
        display: flex;
        flex-direction: column;
    }
    .heading {
        margin-top: 10px;
        font-weight: bold;
        color: black;
    }
    .child-text {
        color: black;
        font-size: 13px;
        text-align: left;
    }
    .gold {
        background: linear-gradient(#ffd782, #f3c334, #eabc32, #ffff95);
    }
    .silver {
        background: linear-gradient(#fffcfc, #c2c2c2, #848484, #969090, #e9e9e9);
    }
    .bronze {
        background: linear-gradient(#ffeaea, #e8b094, #d67650, #da8f69, #ffe8e8);
    }

    .clr {
        width: 70px;
        height: 70px;
        margin: 15px;
        border: 1px solid rgb(208, 208, 208);
        justify-content: center;
        align-items: center;
    }
    img {
        margin: 5px;
    }
</style>
