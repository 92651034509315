<script lang="ts">
    import { userinput } from "../services/stores";

    export let onEnter: () => void;

    let showInstructions = true;

    function on1button() {
        $userinput += "1";
    }
    function on2button() {
        $userinput += "2";
    }
    function on3button() {
        $userinput += "3";
    }
    function on4button() {
        $userinput += "4";
    }
    function on5button() {
        $userinput += "5";
    }
    function on6button() {
        $userinput += "6";
    }
    function on7button() {
        $userinput += "7";
    }
    function on8button() {
        $userinput += "8";
    }
    function on9button() {
        $userinput += "9";
    }
    function on0button() {
        $userinput += "0";
    }
    function onCommabutton() {
        $userinput += ".";
    }
    function onClear(event: MouseEvent & { currentTarget: EventTarget & HTMLButtonElement }) {
        // if (userinput.length > 0) {
        // 	$userinput = userinput.substring(0, userinput.length - 1);
        // }
    }
    function onopenParenthesis() {
        $userinput += "(";
    }
    function oncloseParenthesis() {
        $userinput += ")";
    }
    function onXy() {
        $userinput += "^";
    }
    function onSqrt() {
        $userinput += "√";
    }
    function onX() {
        $userinput += "x";
    }
    function onNumber() {
        $userinput += "n";
    }
    function onMultiply() {
        $userinput += "×";
    }
    function onAdd() {
        $userinput += "+";
    }
    function onSubtract() {
        $userinput += "-";
    }
    function onDivide() {
        $userinput += "/";
    }
    function onSin() {
        $userinput += "sin(x)";
    }
    function onCos() {
        $userinput += "cos(x)";
    }
    function onE() {
        $userinput += "e^x";
    }
    function onLn() {
        $userinput += "ln(x)";
    }
    function onA() {
        $userinput += "a";
    }
    // function onEnter() {
	// 	$userinput = $userinput.replaceAll(",", ".");

	// 	if ($userinput.length > 0) {
	// 		console.log();
	// 		if ($userinput.charAt(0) == "." || $userinput.charAt(0) == ",") {
	// 			$userinput = "0" + userinput;
	// 		}
	// 	}

	// 	// if (started) {
	// 	// 	if ($userinput == "") return;
	// 	// 	CheckAnswer();
	// 	// } else {
	// 	// 	StartGame();
	// 	// }
	// }

    function showHints(): any {
        showInstructions = true;
    }
</script>

<div class="flex-box1">
    <div class="grid-container">
        <button on:click={onSin} class="grid-item-sin custom-button">sin</button>
        <button on:click={onCos} class="grid-item-cos custom-button">cos</button>
        <button on:click={onE} class="grid-item-e custom-button">e</button>
        <button on:click={onLn} class="grid-item-ln custom-button">ln</button>
        <button on:click={onA} class="grid-item-a custom-button">a</button>
        <button on:click={onopenParenthesis} class="grid-item-open-parenthesis custom-button">(</button>
        <button on:click={oncloseParenthesis} class="grid-item-close-parenthesis custom-button">)</button>
        <button on:click={onXy} class="grid-item-xy custom-button">x<sup>y</sup></button>
        <button on:click={onX} class="grid-item-x custom-button">x</button>
        <button on:click={on7button} class="grid-item7 custom-button">7</button>
        <button on:click={on8button} class="grid-item8 custom-button">8</button>
        <button on:click={on9button} class="grid-item9 custom-button">9</button>
        <button on:click={on4button} class="grid-item4 custom-button">4</button>
        <button on:click={on5button} class="grid-item5 custom-button">5</button>
        <button on:click={on6button} class="grid-item6 custom-button">6</button>
        <button on:click={on1button} class="grid-item1 custom-button">1</button>
        <button on:click={on2button} class="grid-item2 custom-button">2</button>
        <button on:click={on3button} class="grid-item3 custom-button">3</button>
        <button on:click={onSqrt} class="grid-item-sqrt custom-button">√</button>
        <button on:click={on0button} class="grid-item-zero custom-button">0</button>
        <button on:click={onCommabutton} class="grid-item-comma custom-button">,</button>
        <button on:click={onEnter} class="grid-item-enter custom-button">Enter</button>
        <button on:click={onNumber} class="grid-item-n custom-button">n</button>
        <button on:click={onClear} class="grid-item-clear custom-button">C <span class="tooltip">Clear</span></button>
        <button on:click={onDivide} class="grid-item-division custom-button">÷</button>
        <button on:click={onMultiply} class="grid-item-multiply custom-button">×</button>
        <button on:click={onSubtract} class="grid-item-subtract custom-button">-</button>
        <button on:click={onAdd} class="grid-item-addition custom-button">+</button>
    </div>
</div>

<style>
    .custom-button {
        background: #ffffff7c;
        border: 2px solid white;
        color: #fff;
        cursor: pointer;
        transition: box-shadow 0.2s ease;
    }

    .custom-button:hover {
        animation: glow-border 0.5s infinite alternate;
    }
    @keyframes glow-border {
        0% {
            border-color: white;
            box-shadow: 0 0 10px white;
        }
        100% {
            border-color: white;
            box-shadow: 0 0 20px white;
        }
    }
    .flex-box1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button {
        width: 75px;
        height: 75px;
        border-radius: 10px;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(5, 1fr);
        font-size: 40px;
        justify-content: center;
        align-items: center;
    }
    .grid-item-sin {
        grid-column: 1/2;
        grid-row: 1/2;
    }
    .grid-item-cos {
        grid-column: 2/2;
        grid-row: 1/2;
    }
    .grid-item-e {
        grid-column: 3/3;
        grid-row: 1/2;
    }
    .grid-item-ln {
        grid-column: 4/4;
        grid-row: 1/2;
    }
    .grid-item-a {
        grid-column: 5/5;
        grid-row: 1/2;
    }
    .grid-item-open-parenthesis {
        grid-column: 1/2;
        grid-row: 2/2;
    }
    .grid-item-close-parenthesis {
        grid-column: 1/2;
        grid-row: 3/3;
    }
    .grid-item7 {
        grid-column: 2/3;
        grid-row: 2/2;
        background: rgba(255, 255, 255, 0.274);
    }

    .grid-item8 {
        grid-column: 3/4;
        grid-row: 2/2;
        background: rgba(255, 255, 255, 0.274);
    }

    .grid-item9 {
        grid-column: 4/5;
        grid-row: 2/2;
        background: rgba(255, 255, 255, 0.274);
    }

    .grid-item4 {
        grid-column: 2/2;
        grid-row: 3/3;
        background: rgba(255, 255, 255, 0.274);
    }

    .grid-item5 {
        grid-column: 3/3;
        grid-row: 3/3;
        background: rgba(255, 255, 255, 0.274);
    }

    .grid-item6 {
        grid-column: 4/4;
        grid-row: 3/3;
        background: rgba(255, 255, 255, 0.274);
    }

    .grid-item1 {
        grid-column: 2/2;
        grid-row: 4/4;
        background: rgba(255, 255, 255, 0.274);
    }

    .grid-item2 {
        grid-column: 3/3;
        grid-row: 4/4;
        background: rgba(255, 255, 255, 0.274);
    }

    .grid-item3 {
        grid-column: 4/4;
        grid-row: 4/4;
        background: rgba(255, 255, 255, 0.274);
    }
    .grid-item-zero {
        grid-column: 3/3;
        grid-row: 5/5;
        background: rgb(69, 69, 69);
    }
    .grid-item-x {
        grid-column: 1/1;
        grid-row: 5/5;
    }
    .grid-item-comma {
        grid-column: 4/4;
        grid-row: 5/5;
    }
    .grid-item-xy {
        grid-column: 1/2;
        grid-row: 4/4;
    }
    .grid-item-sqrt {
        grid-column: 2/2;
        grid-row: 5/5;
    }
    .grid-item-division {
        grid-column: 5/6;
        grid-row: 2/2;
    }
    .grid-item-multiply {
        grid-column: 5/6;
        grid-row: 3/3;
    }
    .grid-item-subtract {
        grid-column: 5/6;
        grid-row: 4/4;
    }
    .grid-item-addition {
        grid-column: 5/6;
        grid-row: 5/5;
    }
    .grid-item-n {
        grid-column: 6/6;
        grid-row: 3/3;
    }
    .grid-item-clear {
        grid-column: 6/6;
        grid-row: 2/2;
        background: rgba(104, 110, 136, 0.79);
        position: relative;
        padding: 10px;
        cursor: pointer;
    }
    .grid-item-clear .tooltip {
        visibility: hidden;
        width: 60px;
        background-color: none;
        color: #fff;
        text-align: center;
        font-size: 18px;
        padding: 5px;
        position: absolute;
        bottom: 110%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        transition:
            visibility 0s linear 0.5s,
            opacity 0.5s ease;
    }
    .grid-item-clear .tooltip::after {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
    }
    .grid-item-clear:hover .tooltip {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.9s;
    }
    .grid-item-enter {
        grid-column: 6/7;
        grid-row: 4/6;
        height: 160px;
        font-size: 20px;
        background: rgba(104, 110, 136, 0.79);
    }
</style>
