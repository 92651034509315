<script lang="ts">
    import "./Home.css";
    import { Link } from "svelte-routing";
    //import { isEquationEditor } from "./services/stores";

    export let taskNumber: number;
    export let progress: number;
    export let memoryTaskTable: TaskInterfaceVm[];

    interface TaskInterfaceVm {
        Url: string;
        Title: string;
        Value: number;
    }

    let title = "";
    let url = "";
    let achieved = 100;

    $: if (memoryTaskTable && taskNumber <= memoryTaskTable.length) {
        //console.log("title:", title);
        //a hack for the scientific calculator
        title = memoryTaskTable[taskNumber - 1].Title;
        if (title == "Derivatives" || title == "Integrals") {
            //url = "keypadeq/" + memoryTaskTable[taskNumber - 1].Url;
            //url = "keypadmem/" + memoryTaskTable[taskNumber - 1].Url;
            //$isEquationEditor = true;
        } else {
           // $isEquationEditor = false;
        }
        url = "keypadmem/" + memoryTaskTable[taskNumber - 1].Url;

        //console.log(`tasknumber ${taskNumber} title: ${title} memoryTaskTable.length() ${memoryTaskTable.length}`);;
    }
</script>

{#if progress >= taskNumber}
    <Link to={url} data-toggle="collapse" data-target=".navbar1-collapse" class="menu-link">
        <div class="small-circle button memory-yellow" {title}>
            {#if achieved == 10}
                <div class="gld circle"></div>
            {:else if achieved == 20}
                <div class="slvr circle"></div>
            {:else if achieved == 30}
                <div class="brnz circle"></div>
            {/if}
        </div>
    </Link>
    <span class="title">{title}</span>
{:else}
    <div class="small-circle button gray" {title}></div>
{/if}

<style>
    .circle {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-decoration: none;
        overflow: hidden;
        z-index: 2;
    }
    .circle::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
    }
    .slvr {
        border: 1px solid rgba(255, 255, 255, 0.247);
    }
    .slvr::before {
        background: linear-gradient(#ede8e8, #c2c2c2, #aeaeae, #9a9595, #e0dede);
    }
    .gld {
        border: 1px solid rgba(255, 221, 27, 0.324);
    }
    .gld::before {
        background: linear-gradient(#ffd782, #f3c334, #eabc32, #ffff95);
    }
    .brnz {
        border: 1px solid rgba(255, 255, 255, 0.274);
    }
    .brnz::before {
        background: linear-gradient(#ffd8d8, #e8b094, #d67650, #da8f69, #ffd2d2);
    }
    .title {
        font-size: 10px;
        font-weight: bold;
        z-index: 3;
        position: absolute;
        bottom: 1px;
        pointer-events: none;
    }
</style>
