<script lang="ts">
    import { navigate } from "svelte-routing";
    import { toast } from "@zerodevx/svelte-toast";
    import { onMount, onDestroy } from "svelte";
    import { fetchGet, fetchPost } from "../services/fetch";
    import type { SvelteComponent } from "svelte";
    //import { newEvent } from "./services/stores";
    //import { updateRating } from "./services/Contact";
    import { AppliedQuestionClientVm } from "./AppliedQuestionVm";
    //import AppliedImage from "./AppliedImage.svelte";

    let questionsVm: AppliedQuestionClientVm[] = [];

    async function load() {
        const clientUrl = `/AppliedMath/GetProblems`;
        questionsVm = await fetchGet<AppliedQuestionClientVm[]>(clientUrl);
    }

    onMount(() => {
        load();
    });

    let showAll = false;

    $: heading = showAll ? "All tasks" : "Tasks created by me";
</script>

<div class="content">
    <div class="header-container">
        <p>
            <input type="checkbox" bind:checked={showAll} /> Show all applied math problems
        </p>
        <h2>{heading}</h2>
    </div>
    <div class="edit-table">
        <div class="table">
            <div class="header cell">Username</div>
            <div class="header cell">charid</div>
            <div class="header cell">Title</div>
            <div class="header cell">Created_At</div>
            <div class="header cell">Suggested_Rating</div>
            <div class="header cell">Calculated_Rating</div>
            <div class="header cell">StepCount</div>
            <div class="header cell">Times solved</div>
            <div class="header cell">No. of Correct</div>
            <div class="header cell">No. of Wrong</div>
            <div></div>
            {#each questionsVm as question}
                <div class="row">
                    <div class="cell">{question.user}</div>
                    <div class="cell"><a class="link" href="applied/{question.charid}">{question.charid}</a></div>
                    <div class="cell"><a class="link" href="applied/{question.charid}">{question.title}</a></div>
                    
                    <div class="cell">{question.created_date}</div>
                    <div class="cell">{question.suggested_rating}</div>
                    <div class="cell">{question.calculated_rating}</div>
                    <div class="cell">{question.stepCount}</div>
                    <div class="cell">{question.solved_times}</div>
                    <div class="cell">{question.correct}</div>
                    <div class="cell">{question.wrong}</div>
                    <button on:click={() => navigate(`applied/${question.charid}/edit`)}>Edit</button>
                </div>
            {/each}
        </div>
    </div>
</div>

<style>
    .table {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        gap: 5px;
        width: auto;
        height: auto;
    }
    @media (max-width: 300px) {
        .edit-table {
            width: auto;
            display: flex;
            overflow-x: auto;
            overflow-y: auto;
            width: 100%;
        }
    .content{
        width: 100%;
        height: 100%;
    }
    .cell {
        display: flex;
        border: none;
        justify-content: center;
        align-items: center;
    }
}

    .cell {
        padding: 5px;
        border: 1px solid #ccc;
    }
    .header {
        font-weight: bold;
    }
    .row {
        display: contents;
    }
    .link {
        color: white;
        text-decoration: underline;
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .header-container {
        display: flex;
        flex-direction: column;
    }
    .edit-table {
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        width: 100%;
    }
    /* @media (max-width: 300px) {
        .edit-table {
            width: auto;
            display: flex;
            overflow-x: auto;
            width: 100%;
        }
    } */
    button {
        width: 120px;
        color: white;
    }
</style>
