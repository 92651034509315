<!-- Keypad.svelte -->
<script lang="ts">
	import { navigate } from "svelte-routing";
	import { toast } from "@zerodevx/svelte-toast";
	import { onMount, onDestroy } from "svelte";
	import { fetchGet, fetchPost } from "../services/fetch";
	import type { SvelteComponent } from "svelte";
	import Score from "./Score.svelte";
	import GameOver from "./Gameover.svelte";
	import HiScore from "./HiScore.svelte";
	import { type ResultInterface } from "./ResultInterface";
	import { isMobile, volume, rating, showDailyLimitAlert, inkeypad, showInstructions, showInfinitePlayButton } from "../services/stores";
	import { updateRating } from "../services/Contact";
	import GameUnlocked from "../GameUnlocked.svelte";
	import Trophy from "../Keypadmem/Trophy.svelte";

	export let slug: string;

	let paramNo = 0;
	let results: ResultInterface[] = []; //used for the results page. Not sure this should be a property
	let userAnswers: MathTask[] = [];
	let InstructionComponent: typeof SvelteComponent | null = null;
	let paramA: string = "-1";
	let paramB: string = "-1";

	let isGameOver = false;
	let isShowHighScore = false;
	let isSecondTry = false;
	let totalPoints = 0;

	//maybe these should have been loaded in another component
	let goldTime = 0;
	let silverTime = 0;
	let bronzeTime = 0;
	let avgGoldTime = 0;
	let avgSilverTime = 0;
	let avgBronzeTime = 0;

	let questionStr = "";
	let userinput = "";
	let stopWatchSeconds = 0;
	let stopWatchId: number = 0;
	let tasksLeft = " ";
	let started = false;
	let isShowScore = false;
	let correctCount = 0;
	let wrongCount = 0;
	let lifeLeft = 2; //Start=2, [0-4]
	let currentTask: MathTask;
	let startTime = new Date();
	let millisecondsSpent = 0;
	let timePoints = 0; //time + penalty
	let lessonDescription = "Loading";
	let inputElement: HTMLInputElement | null = null;
	let taskIter = 0;
	let lessonData: Lesson;
	let hintDisplayed = false;
	let achievedNewProgress = false;
	let trophyColor: string | undefined = undefined;
	let hintExists = true;
	//let hintsOpen = false;
	//let hintsShownNumber = 0; //We need to send this to the server, to subtract points, if we use the hints.

	$: isGameOver = lifeLeft == -1;
	$: progressBarWidth = totalAttempts > 0 ? (correctCount / totalAttempts) * 100 : 0;
	$: totalAttempts = correctCount + wrongCount;
	$: handleRouteChange(slug);
	$: taskCount = lessonData ? lessonData.tasks.length : 0;
	$: if (currentTask) {
		//console.log("if (currentTask) { A, B" + paramA + " " + paramB); //makes paras reactive
		paramA = currentTask.paramA;
		paramB = currentTask.paramB;
	}
	//	$: console.log("lifeLeft=" + lifeLeft);
	//	$: console.log("taskIter=" + taskIter);

	async function handleRouteChange(slug: string) {
		focusInput();
		let authorized = await setCurrentLesson(slug);
		if (authorized) {
			await GetCurrentLessonQuestions();
			taskIter = 0;
			//sets paramA, paramB
			showCurrentTask();
			loadHint();
		}
	}
	$: if (isGameOver) {
		if (gameoverSound) {
			gameoverSound.play();
		}
	}

	let correctSound: HTMLAudioElement;
	let gameoverSound: HTMLAudioElement;
	let wrongSound: HTMLAudioElement;
	let levelupSound: HTMLAudioElement;

	interface MathTask {
		id: number;
		answer: string;
		task: string;
		userAnswer: string;
		paramNo: number;
		paramA: string;
		paramB: string;
		clientTime: number;
		points: number;
		hintDisplayed: boolean;
	}

	interface Lesson {
		name: string;
		description: string;
		goldTime: number;
		silverTime: number;
		bronzeTime: number;
		firstExtraLifeAt: number;
		secondExtraLifeAt: number;
		tasks: MathTask[];
	}

	interface FastMentalMathScoreVm {
		id: number;
		points: number;
		totalMilliseconds: number;
		CorrectCount: number;
		WrongCount: number;
		unlockedLevel: boolean;
	}

	interface UserAnswersVm {
		MathTasks: MathTask[];
		//HintsUsedNumber: number;
	}

	//using the mouse/fingers on the screen elements
	function on1button() {
		userinput += "1";
	}
	function on2button() {
		userinput += "2";
	}
	function on3button() {
		userinput += "3";
	}
	function on4button() {
		userinput += "4";
	}
	function on5button() {
		userinput += "5";
	}
	function on6button() {
		userinput += "6";
	}
	function on7button() {
		userinput += "7";
	}
	function on8button() {
		userinput += "8";
	}
	function on9button() {
		userinput += "9";
	}
	function on0button() {
		userinput += "0";
	}
	function onCommabutton() {
		userinput += ".";
	}

	function onClear(event: MouseEvent & { currentTarget: EventTarget & HTMLButtonElement }) {
		if (userinput.length > 0) {
			userinput = userinput.substring(0, userinput.length - 1);
		}
	}

	function onStopWatchChange() {
		let currentTime = new Date().getTime();
		stopWatchSeconds = (currentTime - startTime.getTime()) / 1000;
	}

	//Would be better that this is active only if this component is visible
	function onEnter() {
		if (isShowScore || isGameOver) return;

		userinput = userinput.replaceAll(",", ".");

		if (userinput.length > 0) {
			//console.log();
			if (userinput.charAt(0) == "." || userinput.charAt(0) == ",") {
				userinput = "0" + userinput;
			}
		}

		if (started) {
			if (userinput == "") return;
			CheckAnswer();
		} else {
			StartGame();
		}
		hintDisplayed = false;
	}

	async function loadHint() {
		try {
			const componentMap: any = {
				small: () => import("../instructions/mental/smallhint.svelte"),
				"ab×power-of-ten": () => import("../instructions/mental/AB_times_power_of_ten.svelte"),
				"ab-div-power-of-ten": () => import("../instructions/mental/Divide_10_tothepowerof_n.svelte"),
				"ab×5": () => import("../instructions/mental/AB_times5.svelte"),
				"ab×11-no-carry": () => import("../instructions/mental/AB_times_11_no_carry.svelte"),
				"ab×11-with-carry": () => import("../instructions/mental/AB_times_11_with_carry.svelte"),

				"abpluscd-no-carry": () => import("../instructions/mental/ABplusCD_no_carry.svelte"),
				"abpluscd-with-carry": () => import("../instructions/mental/ABplusCD_with_carry.svelte"),

				"abc-plus-def-no-carry": () => import("../instructions/mental/ABC_plus_DEF.svelte"),
				"abc-plus-def-with-1-carry": () => import("../instructions/mental/ABC_plus_DEF.svelte"),
				"abc-plus-def-with-2-carry": () => import("../instructions/mental/ABC_plus_DEF.svelte"),
				"abc-plus-def-with-3-carry": () => import("../instructions/mental/ABC_plus_DEF.svelte"),

				addab5: () => import("../instructions/mental/AddAB5.svelte"),

				adda5: () => import("../instructions/mental/AddA5.svelte"),
				addab3: () => import("../instructions/mental/AddAB3.svelte"),
				"a5-squared": () => import("../instructions/mental/A5_squared.svelte"),
				"ab-squared": () => import("../instructions/mental/AB_squared.svelte"),

				"ab×c-no-overlap": () => import("../instructions/mental/AB_times_C.svelte"),
				"ab×c-with-overlap": () => import("../instructions/mental/AB_times_C.svelte"),
				"ab×c-with-carry": () => import("../instructions/mental/AB_times_C.svelte"),
				"ab×c": () => import("../instructions/mental/AB_times_C.svelte"),

				"abc×d-no-overlap": () => import("../instructions/mental/ABC×D_no_overlap.svelte"),
				"abc×d-with-1-overlap": () => import("../instructions/mental/ABC×D_1_overlap.svelte"),
				"abc×d-with-2-overlap": () => import("../instructions/mental/ABC×D_2_overlap.svelte"),
				"abc×d-with-1-carry": () => import("../instructions/mental/ABC×D_1_carry.svelte"),
				"abc×d-with-2-carry": () => import("../instructions/mental/ABC×D_2_carry.svelte"),
				"abc×d": () => import("../instructions/mental/ABC×D.svelte"),

				"ab-minus-cd-noborrow": () => import("../instructions/mental/AB-CD.svelte"),
				"ab-minus-cd-with-borrow": () => import("../instructions/mental/AB-CD.svelte"),

				"abc-minus-def-no-borrow": () => import("../instructions/mental/ABC-DEF.svelte"),
				"abc-minus-def-with-1-borrow": () => import("../instructions/mental/ABC-DEF.svelte"),
				"abc-minus-def-with-2-borrow": () => import("../instructions/mental/ABC-DEF.svelte"),

				"ab×cd-factoring": () => import("../instructions/mental/ab_times_cd_factoring.svelte"),
				"ab×cd-addition": () => import("../instructions/mental/ab_times_cd_addition.svelte"),
			};

			if (componentMap[slug]) {
				let module1 = componentMap[slug];
				//console.log("Importing component module:", module1); // Log the imported module

				const module = await componentMap[slug]();
				//console.log("Imported component module:", module); // Log the imported module
				InstructionComponent = module.default;
				//console.log("InstructionComponent set to:", InstructionComponent); // Log the component itself
				hintExists = true;
				//console.log("Component found for slug:" + slug);
			} else {
				//console.log("Component not found for slug:" + slug);
				console.error("Component not found for slug:" + slug);
				hintExists = false;
			}
		} catch (error) {
			//console.log("Error loading component:" + error);
			console.error("Error loading component:" + error);
			hintExists = false;
		}
	}

	async function StartGame() {
		var clientUrl = "/FastMentalMath/StartCurrentLesson/";
		await fetchGet<void>(clientUrl);

		if (stopWatchId) clearInterval(stopWatchId);

		startTime = new Date();
		stopWatchSeconds = 0;
		stopWatchId = setInterval(onStopWatchChange, 100) as unknown as number;

		started = true;

		setTimeout(() => {
			focusInput();
		}, 0);
	}

	function updateLife() {
		//Update lifes
		console.log(`taskiter=${taskIter} lifeLeft=${lifeLeft}`);
		if (lessonData.firstExtraLifeAt == taskIter) {
			console.log(`INCREASE`);
			if (lifeLeft < 4) lifeLeft++;
		}
		if (lessonData.secondExtraLifeAt == taskIter) {
			console.log(`INCREASE`);
			if (lifeLeft < 4) lifeLeft++;
		}
		console.log(`taskiter=${taskIter} lifeLeft=${lifeLeft}`);
	}

	async function CheckAnswer() {
		updateLife();

		let IsCorrect = false;

		if (currentTask) {
			const myAnswer: MathTask = {
				id: currentTask.id,
				answer: currentTask.answer, //the correct
				task: currentTask.task,
				userAnswer: userinput,
				paramA: "",
				paramB: "",
				paramNo: -1,
				clientTime: 100000,
				points: -1000,
				hintDisplayed: hintDisplayed,
			};

			if (!isSecondTry) userAnswers.push(myAnswer);

			var answer = currentTask.task + " = " + currentTask.answer;

			let isCorrectAnswer = false;
			let points = -20;

			if (userinput == currentTask.answer) {
				correctSound.play();
				isCorrectAnswer = true;
				toast.push("Correct " + answer, {});

				ItsCorrect(currentTask.id);
				correctSound.play();
				IsCorrect = true;
			} else {
				wrongSound.play();
				points = -20;
				$showInstructions = true;
				const toastId = toast.push(answer, {
					//Wrong task = answer
					classes: ["error"],
					initial: 0,
					dismissable: true,
				});
				setTimeout(() => {
					toast.pop(toastId);
				}, 3000);
				await ItsWrong(currentTask.id);
			}

			let currentTime = new Date().getTime();
			stopWatchSeconds = (currentTime - startTime.getTime()) / 1000;

			var result: ResultInterface = {
				Question: currentTask.task,
				Answer: currentTask.answer,
				TimeSpent: 0,
				YourAnswer: userinput,
				IsCorrect,
				StopWatchSeconds: stopWatchSeconds,
				Points: points,
			};
			if (results.length == 0) result.TimeSpent = stopWatchSeconds;
			else {
				let prevResult = results[results.length - 1];
				result.TimeSpent = stopWatchSeconds - prevResult.StopWatchSeconds;
			}
			myAnswer.clientTime = result.TimeSpent;

			if (isCorrectAnswer) {
				let avgGold = lessonData.goldTime / lessonData.tasks.length;
				let avgSilver = lessonData.silverTime / lessonData.tasks.length;
				let avgBronze = lessonData.bronzeTime / lessonData.tasks.length;

				if (result.TimeSpent <= avgGold) points = 5;
				else if (result.TimeSpent <= avgSilver) points = 4;
				else if (result.TimeSpent <= avgBronze) points = 3;
				else points = 2;

				if (hintDisplayed) points--;

				result.Points = points;
			}

			results.push(result);

			userinput = "";
		} else {
			toast.push("Internal error no current task", {
				classes: ["error"],
				initial: 0,
				dismissable: true, // Adds a close button
			});
		}

		if (IsCorrect) {
			taskIter++;
			isSecondTry = false;

			//TODO Should we only send score if it is correct?

			if (taskIter >= taskCount) {
				//finished
				clearInterval(stopWatchId);
				await sendScore();
				//await getUnlockedLevel();
				started = false;
				isShowScore = true; //The highscore is read when this is set to true, so we need to send the score before we read the highsocres
			} else {
				//Make new question
				showCurrentTask();
			}
		}
	}

	//sets currentTask from lessonData
	function showCurrentTask() {
		//console.log("**showCurrentTask");
		$showInstructions = false;

		currentTask = lessonData.tasks[taskIter];
		userinput = "";
		questionStr = currentTask.task;
		tasksLeft = (taskCount - taskIter).toString();

		paramNo = currentTask.paramNo;
		paramA = currentTask.paramA;
		paramB = currentTask.paramB;

		//console.log("showCurrentTask A/B");
		//console.log(paramA);
		//console.log(paramB);
	}

	onMount(() => {
		$inkeypad = true;

		window.addEventListener("keyup", globalKeyupHandler);
		window.addEventListener("click", handleClickOutside);
		correctSound = document.getElementById("correctSound") as HTMLAudioElement;
		gameoverSound = document.getElementById("gameoverSound") as HTMLAudioElement;
		wrongSound = document.getElementById("wrongSound") as HTMLAudioElement;
		levelupSound = document.getElementById("levelupSound") as HTMLAudioElement;

		if (correctSound && gameoverSound && levelupSound && wrongSound) {
			correctSound.load();
			gameoverSound.load();
			levelupSound.load();
			wrongSound.load();

			correctSound.volume = $volume;
			gameoverSound.volume = $volume;
			levelupSound.volume = $volume;
			wrongSound.volume = $volume;
		}
	});
	onDestroy(() => {
		$inkeypad = false;
		window.removeEventListener("click", handleClickOutside);
	});
	function handleClickOutside(event: MouseEvent) {
		const highscoreElement = document.querySelector(".highscore-list");
		if (highscoreElement && !highscoreElement.contains(event.target as Node)) {
			isShowHighScore = false;
		}
	}

	function globalKeyupHandler(event: KeyboardEvent) {
		if (event.key === "Enter") {
			onEnter();
		}
	}

	async function ItsCorrect(id: number) {
		correctCount++;
		updateTimePoints();

		if (timePoints / 1000 < avgGoldTime) $rating += 5;
		else if (timePoints / 1000 < avgSilverTime) $rating += 4;
		else if (timePoints / 1000 < avgBronzeTime) $rating += 3;
		else $rating += 2;
	}

	async function ItsWrong(id: number) {
		wrongCount++;
		lifeLeft--;
		updateTimePoints();

		$rating -= 20;
	}
	function updateTimePoints() {
		var currentTime = new Date();
		millisecondsSpent = currentTime.getTime() - startTime.getTime();
		timePoints = millisecondsSpent;
	}

	async function setCurrentLesson(slug: string): Promise<boolean> {
		var clientUrl = "/FastMentalMath/SetCurrentLesson/" + slug;
		var authorized = await fetchGet<boolean>(clientUrl);

		if (!authorized) {
			//alert("user is not authorized for this level");

			$showDailyLimitAlert = true;
			navigate("/");
		}
		return authorized;
	}

	async function GetCurrentLessonQuestions() {
		//console.log("***GetCurrentLessonQuestions. Reads lessonData");
		try {
			lessonData = await fetchGet<Lesson>("/FastMentalMath/GetCurrentLessonQuestions/");
			console.log("***Got lesson data");
			console.log(lessonData);

			lessonDescription = lessonData.description;

			goldTime = lessonData.goldTime;
			silverTime = lessonData.silverTime;
			bronzeTime = lessonData.bronzeTime;

			avgGoldTime = lessonData.goldTime / lessonData.tasks.length;
			avgSilverTime = lessonData.silverTime / lessonData.tasks.length;
			avgBronzeTime = lessonData.bronzeTime / lessonData.tasks.length;
		} catch (error) {
			// Error handling if needed, the error would have already been logged in fetchGet
			console.log("***Got error");
			//console.log(error);
		}
		//console.log("*** Leaving GetCurrentLessonQuestions");
	}

	async function sendScore() {
		var clientUrl = "/FastMentalMath/ScoreCurrentLesson";
		let userAnswersVm: UserAnswersVm = {
			MathTasks: userAnswers,
			//HintsUsedNumber: hintsShownNumber,
		};
		let result = await fetchPost<FastMentalMathScoreVm, UserAnswersVm>(clientUrl, userAnswersVm);

		if (result.totalMilliseconds / 1000 < goldTime) {
			trophyColor = "gold";
		} else if (result.totalMilliseconds / 1000 < silverTime) {
			trophyColor = "silver";
		} else if (result.totalMilliseconds / 1000 < bronzeTime) {
			trophyColor = "bronze";
		}

		achievedNewProgress = result.unlockedLevel;
		if (achievedNewProgress) {
			if (levelupSound) {
				levelupSound.play();
			}
		}

		timePoints = result.totalMilliseconds / 1000.0;
		totalPoints = result.points;

		updateRating();
	}

	function focusInput() {
		if (inputElement) {
			inputElement.focus();
		}
	}

	function showHints(): any {
		//hintsOpen = !hintsOpen;
		$showInstructions = !$showInstructions;
		if ($showInstructions) {
			hintDisplayed = true;
			if (currentTask.hintDisplayed == false) {
				$rating -= 10;
				currentTask.hintDisplayed = true;
			}
		}
	}

	function showHighScore() {
		isShowHighScore = !isShowHighScore;
	}

	function restart(): any {
		location.reload();
	}

	function onCloseTrophy() {
		trophyColor = undefined;
	}
</script>

<GameUnlocked {achievedNewProgress}></GameUnlocked>
<div class="score-button">
	<button on:click={() => navigate("/home")} class="bttn home-button">
		<img
			alt="home"
			src="/homeicon.svg"
			style="display: inline-block; vertical-align: middle; background-color:white; margin-bottom:2px;"
			width="15px"
			height="15px"
		/>Home
	</button>
	<button on:click={() => showHighScore()} class="bttn">Highscore</button>
	<button on:click={() => restart()} class="bttn">
		<img
			alt="restart"
			src="/restart.svg"
			style="display: inline-block; vertical-align: middle;"
			width="15px"
			height="20px"
		/>Restart</button
	>
</div>

{#if trophyColor}
	<Trophy color={trophyColor} onClose={onCloseTrophy}></Trophy>
{/if}

{#if isShowHighScore}
	<HiScore {slug}></HiScore>
{/if}
<audio id="correctSound" src="/correct.mp3" preload="auto"></audio>
<audio id="wrongSound" src="/wrong.mp3" preload="auto"></audio>
<audio id="levelupSound" src="/level-up.mp3" preload="auto"></audio>
<audio id="gameoverSound" src="/game-over.mp3" preload="auto"></audio>
<!-- <h1>*  {questionStr} debug * </h1> -->
<div class="content">
	<div class="game">
		{#if isGameOver}
			<GameOver {results} {avgGoldTime} {avgSilverTime} {avgBronzeTime}></GameOver>
		{:else if isShowScore}
			<Score
				{results}
				{slug}
				{progressBarWidth}
				{taskCount}
				{correctCount}
				{wrongCount}
				{timePoints}
				{totalPoints}
				{goldTime}
				{silverTime}
				{bronzeTime}
				{avgGoldTime}
				{avgSilverTime}
				{avgBronzeTime}
			></Score>
		{:else}
			<div class="keypad">
				<!-- isMobile: {$isMobile} -->
				<div>
					<div class="level">
						<h1 class="custom-h1">
							<p class="title">{@html lessonDescription}</p>
						</h1>
					</div>

					<div class="flex-box2">
						<div class="task-timer">
							<div>Tasks Left: {tasksLeft}</div>

							<div class="puzzle-container">
								<div class="puzzle-piece">
									{#if lifeLeft == 0}
										<img alt="lives" src="/nolife.svg" style="width: 50px; height: 50px;" />
										<span class="tooltip1">No extra lives remaining</span>
									{:else if lifeLeft == 1}
										<img alt="lives" src="/1life.svg" style="width: 60px; height: 60px;" />
										<span class="tooltip1">1 extra life remaining</span>
									{:else if lifeLeft == 2}
										<img alt="lives" src="/2lives-extra.svg" style="width: 60px; height: 60px;" />
										<span class="tooltip1">2 extra lives remaining</span>
									{:else if lifeLeft == 3}
										<img alt="lives" src="/3lives.svg" style="width: 90px; height: 90px;" />
										<span class="tooltip1">3 extra lives remaining</span>
									{:else if lifeLeft == 4}
										<img alt="lives" src="/4lives.svg" style="width: 115px; height: 90px;" />
										<span class="tooltip1">4 extra lives remaining</span>
									{/if}
								</div>
							</div>

							<!-- <div>{(elapsed / 1000).toFixed(1)}s</div> -->
							<div>Time: {stopWatchSeconds.toFixed(1)}</div>
						</div>
					</div>
					<div class="container">
						<div>
							{#if InstructionComponent && $showInstructions && started}
								<svelte:component this={InstructionComponent} A={paramA} B={paramB} {slug} />
							{/if}
						</div>

						<div class="background">
							{#if started}
								<div>
									<div class="flex-box">
										{questionStr}
									</div>
								</div>
							{:else}
								<div>
									<div class="flex-box">Press "Enter" to start</div>
								</div>
							{/if}
						</div>

						<div class="input-container">
							{#if started}
								<input type="text" class="my-input" bind:value={userinput} bind:this={inputElement} readonly={$isMobile} />
							{/if}
						</div>
					</div>
					<div class="flex-box1">
						<div class="grid-container">
							<button on:click={on7button} class="grid-item7 custom-button">7</button>
							<button on:click={on8button} class="grid-item8 custom-button">8</button>
							<button on:click={on9button} class="grid-item9 custom-button">9</button>
							<button on:click={on4button} class="grid-item4 custom-button">4</button>
							<button on:click={on5button} class="grid-item5 custom-button">5</button>
							<button on:click={on6button} class="grid-item6 custom-button">6</button>
							<button on:click={on1button} class="grid-item1 custom-button">1</button>
							<button on:click={on2button} class="grid-item2 custom-button">2</button>
							<button on:click={on3button} class="grid-item3 custom-button">3</button>
							<button on:click={on0button} class="grid-item-zero custom-button">0</button>
							<button on:click={onCommabutton} class="grid-item-comma custom-button">,</button>
							<button on:click={onEnter} class="grid-item-enter custom-button">Enter</button>
							<button on:click={onClear} class="grid-item-clear custom-button">C <span class="tooltip">Clear</span></button>
							{#if started && hintExists}
								<button on:click={() => showHints()} class="hint">
									{#if $showInstructions}Close Hint
									{:else}Show Hint!
									{/if}</button
								>
							{/if}
						</div>
					</div>
				</div>
			</div>
		{/if}
	</div>
</div>

<style>
	@import "../global.css";
	.custom-button {
		background: #ffffff7c;
		border: 2px solid white;
		color: #fff;
		cursor: pointer;
		/*		transition: box-shadow 0.2s ease; */
	}
	.hint {
		background: none;
		border: 2.5px solid yellow;
		color: #fff;
		width: 75px;
		height: 75px;
		font-size: 18px;
		/*		animation: pulse 1s infinite; */
	}
	.custom-button:hover {
		animation: glow-border 0.5s infinite alternate;
	}
	@keyframes glow-border {
		0% {
			border-color: white;
			box-shadow: 0 0 10px white;
		}
		100% {
			border-color: white;
			box-shadow: 0 0 20px white;
		}
	}

	.bttn {
		width: 100px;
		height: 30px;
		/*animation: pulse;*/
	}
	.score-button {
		display: flex;
		flex-direction: row;
	}
	.keypad {
		display: flex;
		top: 5%;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.content {
		display: flex;
		flex-direction: row;
		background-color: #000;
	}

	.level {
		color: white;
		text-align: center;
		font-size: x-large;
		font-weight: bold;
	}

	.background {
		display: flex;
		width: 500px;
		height: 180px;
		justify-content: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.274);
		border: 1px solid white;
	}

	.flex-box {
		color: #fff;
		font-size: 40px;
		font-weight: bolder;
		text-align: center;
	}

	.input-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 10px;
		height: 50px;
	}

	.my-input {
		width: 300px;
		height: 50px;
		background-color: #fff;
		border: 3px solid gray;
		color: #000;
		font-size: 30px;
		text-align: center;
		line-height: 50px;
	}

	.my-input:focus {
		outline: none;
		border-color: blue;
	}

	.flex-box1 {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.flex-box2 {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.task-timer {
		display: flex;
		position: center;
		font-size: 25px;
		width: 500px;
		height: 50px;
		padding: 10px;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.4);
	}

	button {
		width: 75px;
		height: 75px;
		margin: 5px;
		border-radius: 10px;
	}

	.grid-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(4, 1fr);
		font-size: 40px;
		justify-content: center;
		align-items: center;
		padding-top: 5px;
	}
	.grid-item7 {
		grid-column: 1/2;
		grid-row: 1/2;
	}

	.grid-item8 {
		grid-column: 2/3;
		grid-row: 1/2;
	}

	.grid-item9 {
		grid-column: 3/4;
		grid-row: 1/2;
	}

	.grid-item4 {
		grid-column: 1/2;
		grid-row: 2/3;
	}

	.grid-item5 {
		grid-column: 2/3;
		grid-row: 2/3;
	}

	.grid-item6 {
		grid-column: 3/4;
		grid-row: 2/3;
	}

	.grid-item1 {
		grid-column: 1/2;
		grid-row: 3/4;
	}

	.grid-item2 {
		grid-column: 2/3;
		grid-row: 3/4;
	}

	.grid-item3 {
		grid-column: 3/4;
		grid-row: 3/4;
	}

	.grid-item-enter {
		grid-column: 4/5;
		grid-row: 3/5;
		height: 160px;
		font-size: 20px;
	}

	.grid-item-zero {
		grid-column: 1/3;
		grid-row: 4/5;
		width: 160px;
	}

	.grid-item-comma {
		grid-column: 3/4;
		grid-row: 4/5;
	}
	.grid-item-clear {
		grid-column: 4/5;
		grid-row: 2/2;
		position: relative;
		padding: 10px;
		cursor: pointer;
	}
	.grid-item-clear .tooltip {
		visibility: hidden;
		width: 60px;
		background-color: none;
		color: #fff;
		text-align: center;
		font-size: 18px;
		padding: 5px;
		position: absolute;
		bottom: 110%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		transition:
			visibility 0s linear 0.5s,
			opacity 0.5s ease;
	}
	.grid-item-clear .tooltip::after {
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
	}
	.grid-item-clear:hover .tooltip {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.9s;
	}
	.title {
		font-size: 45px;
		margin-top: 40px;
		padding: 0;
	}
	.custom-h1 {
		padding: 0;
		margin: 0;
		width: 500px;
	}
	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.puzzle-container {
		display: flex;
		gap: 10px;
	}

	.puzzle-piece {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.puzzle-piece img {
		display: block;
	}

	.tooltip1 {
		visibility: hidden;
		background-color: #333;
		color: #fff;
		text-align: center;
		padding: 5px 10px;
		font-size: 15px;
		border-radius: 5px;
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
		z-index: 1;
		opacity: 0;
		transition:
			visibility 0s linear 0.5s,
			opacity 0.5s ease;
	}

	.puzzle-piece:hover .tooltip1 {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.9s;
	}
</style>
