<script lang="ts">
    export let onContinue: () => void;
    import { onMount, onDestroy } from 'svelte';

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            onContinue();
        }
    }

    onMount(() => {
        document.addEventListener('keydown', handleKeydown);
    });

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeydown);
    });
</script>
<div class=" info-window">
    <div class="subheading">Intro Math</div>
    <!-- <img alt="icon" src="/homepage.png" style="" width="60px" height="60px" /> -->
    <div class="smalltext">
        Are you ready to discover where you stand and how high you can climb? Let's get calculating!
    </div>
    <button on:click={onContinue}  class="continue-btn buttn"> Continue</button>
</div>

<style>
    .subheading {
        font-size: 13px;
    }
    .smalltext {
        color: white;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .info-window {
        position: absolute;
        background-color: black;
        color: gold;
        padding: 10px;
        font-size: 10px;
        width: 200px;
        height: 120px;
        border: 2px solid yellow;
        border-radius: 5px;
        left: 140px;
        top: 260px;
        display: block;
        z-index: 9999;
    }
    .continue-btn {
        width: 60px;
        height: 20px;
        font-size: 11px;
        top: 40px;
        left: -200px;
    }
    .continue-btn:hover {
        color: white;
        animation: glow-border 0.8s infinite alternate;
    }
    @keyframes glow-border {
        0% {
            border-color: gold;
            box-shadow: 0 0 5px gold;
        }
        100% {
            border-color: white;
            box-shadow: 0 0 10px gold;
        }
    }
</style>
