<script lang="ts">
    import Achievement from "./Achievement.svelte";
    import Podium from "./Podium.svelte";
</script>

<div class="achievements">
    <Achievement title="Huge Problem Solver" text="Solved 1,000,000 problems" colorval="gold"></Achievement>
    <Achievement title="Problem Solver" text="Solved 100,000" colorval="silver"></Achievement>
    <Achievement title="Minor Problem Solver" text="Solved 10,000" colorval="bronze"></Achievement>
</div>

<style>
    .achievements {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
    }
</style>
