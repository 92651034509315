<script lang="ts">
    import { navigate, Link } from "svelte-routing";
    import { UserService } from "../services/user.service";
    import { toast } from "@zerodevx/svelte-toast";
    import { rating, userName, isAuthenticated } from "../services/stores";
    import { updateRating } from "../services/Contact";
    //When we relaod, this is used to set isAuthenicated/username
    let loggedIn = UserService.isLoggedIn();
    isAuthenticated.set(loggedIn);

    userName.set(UserService.getDisplayName());

    //$: console.log("loggedIn (Reactive): " + loggedIn);

    //I think that $isAuthenticated is lost if we reload
    updateRating(); //for when reloading

    //console.log("Profile");

    //console.log("Profile, username:" + $userName);

    $: if ($isAuthenticated) {
        console.log("Profile, authenticated username:" + $userName);
    } else {
        console.log("Profile, NOT authenticated username:" + $userName);
    }

    // let userName = UserService.getDisplayName();

    // $: userName = UserService.getDisplayName();//Don't think this will work

    function logout() {
        let userService = new UserService();
        userService.logout();
        //loggedIn = UserService.isLoggedIn();
        navigate("/");
        toast.push("See you next time", {
            classes: ["success"],
            pausable: true,
        });
        //loggedIn = UserService.isLoggedIn();
        isAuthenticated.set(false);

        console.log("logged in: " + isAuthenticated);
    }
</script>

{#if $isAuthenticated}
    <div class="flex1">
        <p class="rating">{$rating}</p>
        <div class="username-display">
            {$userName}
            <div class="flex-shrink-0">
                <div class="items-center">
                    <!-- Uncomment and update the image source if you want an avatar -->
                    <!-- <img
                        alt="User"
                        loading="lazy"
                        width="32"
                        height="32"
                        decoding="async"
                        class="rounded-sm"
                        src="https://api.adorable.io/avatars/[200]/[identifier]/yellow.png"
                        style="color: transparent;"
                    /> -->
                </div>
            </div>
            <!-- Optional logout button -->
            <!-- <div class="option">
                <button on:click={() => logout()} class="dropdown">Logout</button>
            </div> -->
        </div>
        
    </div>
{:else}
    <!-- <h1>DEBUG NOT logged in DEBUG</h1> -->
{/if}

<style>
    .rating {
        margin: 0;
        width: auto;
    }
    .username-display {
        display: flex;
        position: relative;
        color: yellow;
        margin-top: 10px;
        background: none;
        width: auto;
        height: 30px;
        margin-left: 5px;
        text-align: center;
        border: none;
    }
    .flex1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        z-index: 6;
        top: 10px;
        right: 0;
        width: auto;
    }
    /* @media (max-width: 545px) {
    .flex1 {
        top: 20px;
        position: absolute;
        left: 130%;
        width: auto;
    }
    .username-button{
        margin-top: 20px;
    }
} */
</style>
