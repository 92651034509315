<script lang="ts">
    import { type ResultInterface } from "./ResultInterface";
    export let results: ResultInterface[];

    export let avgGoldTime;
    export let avgSilverTime;
    export let avgBronzeTime;
</script>

<div class="list">
    <div class="content heading">Question</div>
    <div class="content heading">Answer</div>
    <div class="content heading">Your Answer</div>
    <div class="content heading">Time Spent</div>
    <div class="content heading">Points</div>
    {#each results as result}
        <div class="content">{result.Question}</div>
        <div class="content">{result.Answer}</div>
        <div class="content" class:wrong={!result.IsCorrect}>{result.YourAnswer}</div>
        <div class="content">{result.TimeSpent.toFixed(2)}</div>
        <div class="content">{result.Points}</div>
    {/each}
</div>
<div class="per-task">
    <div>Per Task:</div>
    <div class="item">
        <div class="gld circle"></div>
        <div>&lt;{avgGoldTime}s</div>
    </div>
    <div class="item">
        <div class="slvr circle"></div>
        <div>&lt;{avgSilverTime}s</div>
    </div>
    <div class="item">
        <div class="brnz circle"></div>
        <div>&lt;{avgBronzeTime}s</div>
    </div>
</div>

<style>
    .wrong {
        background-color: rgba(255, 0, 0, 0.514);
    }

    .list {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        gap: 5px;
        /* position: absolute; */
        flex-direction: column;
        text-align: center;
        left: 50px;
        top: 80px;
        color: white;
        background: black;
        width: 570px;
        z-index: 7;
        border: 1px solid rgb(255, 234, 0);
    }
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        border: 1px solid rgba(255, 217, 0, 0.612);
    }
    .heading {
        background: rgb(255, 234, 0);
        color: black;
        font-weight: bold;
    }
    .per-task {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
    .item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }
    .circle {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(204, 194, 78, 0.418);
        border-radius: 50%;
        text-decoration: none;
        overflow: hidden;
        box-shadow: 0 0 10px 3px rgba(255, 248, 168, 0.224);
        animation:
            pulse 4s infinite alternate,
            rotate 2s infinite;
    }
    .circle::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
    }
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    .gld::before {
        background: linear-gradient(#ffd782, #f3c334, #eabc32, #ffd782);
    }
    .slvr {
        border: 1px solid rgba(255, 255, 255, 0.247);
    }
    .slvr::before {
        background: linear-gradient(#ede8e8, #c2c2c2, #aeaeae, #9a9595, #e0dede);
    }
    .brnz {
        border: 1px solid rgba(255, 255, 255, 0.274);
    }
    .brnz::before {
        background: linear-gradient(#ffd8d8, #e8b094, #d67650, #da8f69, #ffd2d2);
    }
</style>
