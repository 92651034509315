export class AppliedQuestionClientVm {
    encodedFullImage: string = "";
    encodedImageHint1: string = ""; //image
    encodedImageHint2: string = "";
    encodedImageHint3: string = "";
    encodedQuestionImage: string = "";
    option1PositionX: number = 0;
    option1PositionY: number = 0;
    option2PositionX: number = 0;
    option2PositionY: number = 0;
    option3PositionX: number = 0;
    option3PositionY: number = 0;
    option4PositionX: number = 0;
    option4PositionY: number = 0;
    step1Y: number = 0;
    step2Y: number = 0;
    step3Y: number = 0;
    stepCount: number = 0;
    suggested_rating: number = 0;
    suggested_time: number = 0;
    title: string = "";
    charid: string = "";
    solved_times: number = 0;
    correct: number = 0;
    wrong: number = 0;
    user: string = "";
    created_date: any;
    calculated_rating: number = 0;
    filename: string = "";

    constructor() {

    }
}