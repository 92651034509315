<script lang="ts">
    export let color = "";
    export let onClose = () => {};
</script>

<div class="medal-popup" id="medalPopup">
    <div id="confetti-wrapper">
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
    </div>
    <div class="medal-container">
        <div class="medal-icon" id="medalIcon">
            {#if color == "gold"}
                <img alt="home" src="/gold-trophy.png" class="gold-trophy" width="150px" height="150px" />
            {/if}
            {#if color == "silver"}
                <img alt="home" src="/silver-trophy.png" class="silver-trophy" width="150px" height="150px" />
            {/if}
            {#if color == "bronze"}
                <img alt="home" src="/bronze-trophy.png" class="bronze-trophy" width="150px" height="150px" />
            {/if}
        </div>
        {#if color == "gold"}
            <h2 class="medal-text" id="medalText">Congratulations! You've earned the Gold Medal!</h2>
        {/if}
        {#if color == "silver"}
            <h2 class="medal-text" id="medalText">Congratulations! You've earned the Silver Medal!</h2>
        {/if}
        {#if color == "bronze"}
            <h2 class="medal-text" id="medalText">Congratulations! You've earned the Bronze Medal!</h2>
        {/if}
        <div class="popup-buttons">
            <button on:click={onClose} id="closeButton">Close</button>
        </div>
    </div>
</div>

<style>
    /* General Reset */
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    /* Medal Popup Styles */
    .medal-popup {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1000;
    }

    .medal-container {
        text-align: center;
        padding: 2rem;
        border-radius: 10px;
        background-color: rgb(0, 0, 0);
        width: 300px;
        animation: fadeIn 0.5s ease;
        border: 2px solid yellow;
        z-index: 1001;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: scale(0.8);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    /* Medal Icon Styles */
    .medal-icon {
        width: 100px;
        height: 100px;
        margin: 0 auto 1rem;
        background-color: black;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 2rem;
        font-weight: bold;
        box-shadow: 0 0 20px rgba(255, 215, 0, 0.6);
        animation: glow 1s infinite alternate;
    }
    @keyframes glow {
        from {
            box-shadow: 0 0 20px 5px rgba(255, 215, 0, 0.6);
        }
        to {
            box-shadow: 0 0 30px 10px rgb(255, 217, 0);
        }
    }

    /* Text Styles */
    .medal-text {
        font-size: 1.25rem;
        margin: 3rem 0 0 0;
        font-weight: bold;
        color: white;
        margin-bottom: 0.5rem;
    }
    /* Buttons */
    .popup-buttons button {
        padding: 0.5rem 1rem;
        margin: 3rem 0 0.5rem 3rem;
        border-radius: 5px;
        cursor: pointer;
    }

    .popup-buttons button:hover {
        opacity: 0.9;
    }

    .popup-buttons button {
        background-color: none;
        color: white;
    }

    /* confetti */

    #confetti-wrapper {
        overflow: hidden;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .confetti {
        width: 15px;
        height: 15px;
        background-color: #f2d74e;
        position: absolute;
        left: 50%;
        animation: confetti 5s ease-in-out -2s infinite;
        transform-origin: left top;
    }

    .confetti:nth-child(1) {
        background-color: #fcf6b0;
        left: 10%;
        animation-delay: 0;
    }

    .confetti:nth-child(2) {
        background-color: #78531e;
        left: 20%;
        animation-delay: -5s;
    }

    .confetti:nth-child(3) {
        background-color: #e3c47a;
        left: 30%;
        animation-delay: -3s;
    }

    .confetti:nth-child(4) {
        background-color: #896c41;
        left: 40%;
        animation-delay: -2.5s;
    }

    .confetti:nth-child(5) {
        background-color: #9a5f22;

        left: 50%;
        animation-delay: -4s;
    }

    .confetti:nth-child(6) {
        background-color: #ddc571;
        left: 60%;
        animation-delay: -6s;
    }

    .confetti:nth-child(7) {
        background-color: #8a5e25;
        left: 70%;
        animation-delay: -1.5s;
    }

    .confetti:nth-child(8) {
        background-color: #fcdf73;
        left: 80%;
        animation-delay: -2s;
    }

    .confetti:nth-child(9) {
        background-color: #4f3a20;
        left: 90%;
        animation-delay: -3.5s;
    }

    .confetti:nth-child(10) {
        background-color: #f0e9a0;
        left: 100%;
        animation-delay: -2.5s;
    }

    .confetti:nth-child(11) {
        background-color: #f0e9a3;
        left: 72%;
        animation-delay: -5.5s;
    }

    .confetti:nth-child(12) {
        background-color: #f0e4a0;
        left: 34%;
        animation-delay: -2.8s;
    }

    .confetti:nth-child(13) {
        background-color: #f0c9a0;
        left: 100%;
        animation-delay: -7.5s;
    }

    .confetti:nth-child(14) {
        background-color: yellow;
        left: 16%;
        animation-delay: -8.5s;
    }

    @keyframes confetti {
        0% {
            transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
        }
        25% {
            transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 10vh);
        }
        50% {
            transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 30vh);
        }
        75% {
            transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 60vh);
        }
        100% {
            transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 90vh);
        }
    }
</style>
