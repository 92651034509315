// s0rc/authStore.ts
import { writable } from 'svelte/store';

export const isAuthenticated = writable(false);
export const connectionTrouble = writable(false);
export const isMobile = writable(false);
export const isLoading = writable(false);
//export const isEquationEditor = writable(false);
export const rating = writable(0);
export const userName = writable("");
export const newEvent = writable("");
export const userinput = writable('');
export const calcEnter = writable(false);
export const showInstructions = writable(false);
export const onLoginPage = writable(false);
export const mathContent = writable("");
export const instructionMathContent = writable("");
export const volume = writable(1.0);
export const redirect = writable(""); //where we wanted to go


//If we choose the react version of the payment
import type { Stripe } from '@stripe/stripe-js';
export const stripePromise = writable<Promise<Stripe | null> | null>(null);

//We will block either when a new game is unlocked, or when you have played like a little bit of the new level
export const needSubscriptionToContinue = writable(false);
export const hasSubscription = writable(false);

export const hasContinuedMathIntro = writable(false);


export const showDailyLimitAlert = writable(false); //If we we hit the daily solve limit
export const inkeypad = writable(false);
export const showInfinitePlayButton = writable(false);

export const PollPaid = writable(false); //Should we poll the server to see if we have paid?













