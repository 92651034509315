<script lang="ts">
    export let onContinue: () => void;
    
    
    import { onMount, onDestroy } from 'svelte';

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            onContinue();
        }
    }

    onMount(() => {
        document.addEventListener('keydown', handleKeydown);
    });

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeydown);
    });
    
</script>

<div class=" info-window">
    <div class="subheading">Mental Intro</div>
    <!-- <img alt="icon" src="/homepage.png" style="" width="60px" height="60px" /> -->
    <div class="smalltext">
        Mental Math Path: Think fast, calculate faster! This path challenges you to solve problems swiftly in your head.
        From multiplying 14 by 3 to quickly figuring out subtraction with borrow and addition with carry, your ability
        to crunch numbers on the fly will skyrocket. Each correct answer boosts your mental math rating, reflecting your
        growing speed and accuracy.
    </div>
    <button on:click={onContinue} class="continue-btn" > Continue</button>
</div>

<style>
    .subheading {
        font-size: 13px;
    }
    .smalltext {
        color: white;
        padding-top: 10px;
    }
    .info-window {
        position: absolute;
        background-color: black;
        color: gold;
        padding: 10px;
        font-size: 10px;
        width: 200px;
        height: 190px;
        border: 2px solid yellow;
        border-radius: 5px;
        left: 240px;
        top: 260px;
        display: block;
        z-index: 9999;
    }
    .continue-btn {
        width: 60px;
        height: 20px;
        font-size: 11px;
        top: 40px;
        left: -200px;
    }
    .continue-btn:hover {
        color: white;
        animation: glow-border 0.8s infinite alternate;
    }
    @keyframes glow-border {
        0% {
            border-color: gold;
            box-shadow: 0 0 5px gold;
        }
        100% {
            border-color: white;
            box-shadow: 0 0 10px gold;
        }
    }
</style>
